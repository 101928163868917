<template>
  <!--
    Lists all the installations in the system.
  -->
  <div class="installationList">
    <div
      v-if="!isResort"
      class="input-group mb-2"
    >
      <div class="input-group-prepend">
        <div class="input-group-text">
          <font-awesome-icon
            class="mr-2 gray"
            icon="search"
          />
          <span>{{ $t('search') }}</span>
        </div>
      </div>
      <input
        ref="searchBar"
        v-model="filter"
        v-focus
        class="form-control"
        @click="kgm_deselectRows"
        @keydown.enter.prevent="kgm_selectRow"
        @keydown.tab.prevent="kgm_selectRow"
      >
    </div>
    <LoadingPlaceholder
      v-if="loading"
      class="mt-4"
    />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :data-items="kgm_computedGridItems(installations)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :selected-field="kgm_selectedField"
        :take="kgm_take"
        :total="kgm_allGridItems(installations)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        style="height: auto;"
        class="mb-3"
        @filterchange="filterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
        @rowclick="kgm_gridOnRowClick"
      >
        <template
          slot="nameLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.id }`"
              class="alt-primary-color"
            >
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </router-link>
          </td>
        </template>
        <template
          slot="statusTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span :class="computedStatus(kgm_getNestedValue(props.field, props.dataItem)).color">{{ computedStatus(kgm_getNestedValue(props.field, props.dataItem)).status }}</span>
          </td>
        </template>
        <template
          slot="monitoringTemplate"
          slot-scope="{props}"
        >
          <td>
            <span :class="kgm_getNestedValue(props.field, props.dataItem)">{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="statusFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="statusSelect"
            :key="`statusSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(status, key) in statusList"
              :key="`deviceSearch-statusListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, status, 'statusSelect')"
            >
              {{ status }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.statusSelect != null && ($refs.statusSelect.value != null && $refs.statusSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('statusSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="typeSelect"
            :key="`typeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, key) in installationTypes"
              :key="`deviceSearch-installationTypeFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, type, 'typeSelect')"
            >
              {{ type }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.typeSelect != null && ($refs.typeSelect.value != null && $refs.typeSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('typeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="idFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="idFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an ID"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('idFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="countryFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="countryFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a countrys name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('countryFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="sponsorFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="sponsorFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a sponsors name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('sponsorFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="abbFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="abbFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a abbreviation"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('abbFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="monitoringFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="zabbixStatusSelect"
            :key="`zabbixStatusSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, key) in zabbixStatusList"
              :key="`deviceSearch-installationTypeFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, type, 'zabbixStatusSelect')"
            >
              {{ type }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.zabbixStatusSelect != null && ($refs.zabbixStatusSelect.value != null && $refs.zabbixStatusSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('zabbixStatusSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="slaFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="slaSelect"
            :key="`slaSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, key) in slaList"
              :key="`deviceSearch-slaFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, type, 'slaSelect')"
            >
              {{ type }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.slaSelect != null && ($refs.slaSelect.value != null && $refs.slaSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('slaSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="envFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="envSelect"
            :key="`envSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, key) in ['Prod', 'Stage']"
              :key="`deviceSearch-envFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, type, 'envSelect')"
            >
              {{ type }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.envSelect != null && ($refs.envSelect.value != null && $refs.envSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('envSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
      <ul class="ml-0 mb-0 pl-4 card-columns">
        <li>
          <code>{{ $t('installationList.arrowKeys') }}</code>
          <span>: {{ $t('installationList.navigateOverCells') }}</span>
        </li>
        <li>
          <code>{{ $t('installationList.enter') }}</code>
          <span>: {{ $t('installationList.openLink') }}</span>
        </li>
        <li>
          <code>{{ $t('installationList.pageUp') }}</code>
          <span>: {{ $t('installationList.navigateToPreviousPage') }}</span>
        </li>
        <li>
          <code>{{ $t('installationList.pageDown') }}</code>
          <span>: {{ $t('installationList.navigateToNextPage') }}</span>
        </li>
        <li>
          <code>{{ $t('installationList.space') }}</code>
          <span>: {{ $t('installationList.deselectRows') }}</span>
        </li>
        <li>
          <code>{{ $t('installationList.leftClick') }}</code>
          <span>: {{ $t('installationList.selectRows') }}</span>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { jiraStatusMixin } from '@/mixins/jiraStatusMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "InstallationList",
  mixins: [
    jiraStatusMixin,
    kendoGridMixin,
    authenticationMixin
  ],
  props: {
    showAll: {
      type: Boolean,
      required: false,
      default () {
        return true;
      }
    },
    rInstallations: {
      type: Array,
      required: false,
      default () {
        return [];
      }
    },
    isResort: {
      type: Boolean,
      required: false,
      default () {
        return false;
      }
    },
    trackerFilter: {
      type: Array,
      required: false,
      default () {
        return null;
      }
    },
    installationName: {
      type: String,
      required: false,
      default: null
    },    
    installationTypeQuery: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      blockLoading: false,
      widget: null,
      loading: true,
      installations: null,
      initInstallations: null,
      kgm_selectable: true,
      filter: "",
      typeFilterValue: null,
      slaList: [ 'No Agreement', "ExternalHosted-Bronze", "ExternalHosted-Silver", "ExternalHosted-Gold", "Summer-and-Winter", "Summer", "Winter", "Gold",
      "Silver", "Bronze"],
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_storage: this.isResort ? '' : 'installationList',
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "name",
            operator: "contains",
            value: ""
          },
          {
            field: "id",
            operator: "contains",
            value: ""
          },
          {
            field: "country",
            operator: "contains",
            value: ""
          },
          {
            field: "sponsor",
            operator: "contains",
            value: ""
          },
          {
            field: "monitoring",
            operator: "contains",
            value: ""
          }
        ]
      },
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.name'),
          cell: 'nameLink',
          filterCell: "nameFilter"
        },
        {
          field: 'customerAbbreviation',
          filterable: true,
          filter: 'text',
          width: '120px',
          title: this.$t('customerAbbreviation'),
          filterCell: "abbFilter",
          hideOn: ['mdDown']
        },
        {
          field: 'installationType',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.type'),
          filterCell: 'typeFilter',
          width: '180px',
          hideOn: ['smDown']
        },
        {
          field: 'id',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.id'),
          width: '175px',
          hideOn: ['xlDown'],
          filterCell: "idFilter"
        },
        {
          field: 'jiraStatus',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.status'),
          filterCell: 'statusFilter',
          hideOn: ['smDown'],
          cell: 'statusTemplate'
        },
        {
          field: 'zabbixStatus',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.monitoring'),
          width: '175px',
          hideOn: ['xsDown'],
          filterCell: "monitoringFilter",
          cell: 'monitoringTemplate',
          hidden: this.authenticationHasRole('scope_customer') && this.authenticationHasRole('zerolevel')
        },
        {
          field: 'zabbixStatus',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.monitoring'),
          hideOn: this.isResort ? ['smUp', 'xsDown'] : ['smUp'],
          filterCell: "monitoringFilter",
          cell: 'monitoringTemplate',
          hidden: this.authenticationHasRole('scope_customer') && this.authenticationHasRole('zerolevel')
        },
        {
          field: 'slaAgreement',
          filterable: true,
          filter: 'text',
          title: 'SLA',
          hideOn: ['mdDown'],
          filterCell: "slaFilter"
        },
        {
          field: 'country',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.country'),
          width: '150px',
          hideOn: ['xlDown'],
          filterCell: "countryFilter"
        },
        {
          field: 'sponsor',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.sponsor'),
          width: '175px',
          hideOn: ['xlDown'],
          filterCell: "sponsorFilter"
        },
        {
          field: 'environment',
          filterable: true,
          filter: 'text',
          title: this.$t('freshdesk.environment'),
          width: '110px',
          hideOn: ['mdDown'],
          filterCell: "envFilter"
        }
      ]
    }
  },
  computed: {
    statusList () {
      if (!this.installations) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let statusList = Array.from(new Set(this.installations.map(o=>o.jiraStatus)));
      //Remove null values
      statusList = statusList.filter(function (e) {return e});
      return statusList.sort();
    },
    selectedItem () {
      return this.installations.find((installation) => installation.id === this.kgm_selectedIndex);
    },
    installationTypes () {
      if (!this.installations) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let installationTypes = Array.from(new Set(this.installations.map(o=>o.installationType)));
      //Remove null values
      installationTypes = installationTypes.filter(function (e) {return e});
      return installationTypes.sort();
    },
    zabbixStatusList: function () {
      if (!this.installations) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let zabbixStatusList = Array.from(new Set(this.installations.map(o => o.zabbixStatus)));
      //Remove null values
      zabbixStatusList = zabbixStatusList.filter((e) => {
        return e;
      });
      
      return zabbixStatusList.sort();
    },
  },
  watch: {
    trackerFilter () {
      this.filterSkimovieType();
    },
    typeFilterValue () {
      if(this.typeFilterValue == "Skimovie") {
        this.$emit("showTrackerFilter", true);
      }
      else {
        this.$emit("showTrackerFilter", false);
      }
    },
    filter () {
      if(!this.isResort) {
        this.searchChange();
      }
    },
    showAll () {
      this.getAllInstallations();
    },
    rInstallations (val) {
      this.loading = true;
      this.installations = val;
      this.loading = false;
    }
  },
  created () {
    if(!this.isResort) {
      this.loadFilter();
      if(this.kgm_filter && this.kgm_filter.filters) {
          var typeFilter = this.kgm_filter.filters.find(x => x.field == "installationType");
          if(typeFilter) {
            this.typeFilterValue = typeFilter.value;
          }
          else {
            this.typeFilterValue = null;
          }
       }
       else {
         this.typeFilterValue = null;
       }
      this.getAllInstallations();  
    }
    else {
      this.loading = true;
      this.installations = this.rInstallations;
      this.loading = false;
    }
  },
  beforeMount () {
    //Every 3 minutes
    this.refreshTriggers = window.setInterval(() => {
      this.autoLoadInstallations();
    }, 60000 * 3);
  },
  mounted () {
    this.kgm_dataIndexes = new Set();
    document.addEventListener("keydown", this.kgm_onKeyPress);
  },
  beforeDestroy () {
    document.removeEventListener("keydown", this.kgm_onKeyPress);
    window.clearInterval(this.refreshTriggers);
    //this.saveFilter();
  },
  methods: {
    filterSkimovieType () {
      if(this.initInstallations) {
        if(this.trackerFilter == null) {
          this.installations = this.initInstallations;
        }
        else if(this.trackerFilter.includes('trackerV4') && this.trackerFilter.includes('trackerV3')) {
          this.installations = this.initInstallations.filter(x => x.skimovieType == 'trackerV4' || x.skimovieType == 'trackerV3');
        }
        else if(this.trackerFilter.includes('trackerV4')) {
          this.installations = this.initInstallations.filter(x => x.skimovieType == 'trackerV4')
        }
        else if(this.trackerFilter.includes('trackerV3')) {
          this.installations = this.initInstallations.filter(x => x.skimovieType == 'trackerV3')
        }
        else {
          this.installations = this.initInstallations;
        }
      }
    },
    filterChange (event) {
       if(event.filter) {
          var typeFilter = event.filter.filters.find(x => x.field == "installationType");
          if(typeFilter) {
            this.typeFilterValue = typeFilter.value;
          }
          else {
            this.typeFilterValue = null;
          }
       }
       else {
         this.typeFilterValue = null;
       }
      
      this.kgm_filter = event.filter;
      this.kgm_saveToStorage();
    },
    saveFilter () {
      if(!this.isResort && !this.installationName) {
        if(this.filter === "")
        {
          localStorage.removeItem('installationListFilter');
        }
        else
        {
          localStorage['installationListFilter'] = JSON.stringify({
            filter: this.filter
          });
        }
      }
    },
    loadFilter () {
      if(!this.isResort) {
        let loadedStorage = localStorage['installationListFilter'];
        if (!loadedStorage) {
          if(this.installationName) {
            this.filter = this.installationName;
            this.blockLoading = true;
          }
          return;
        }
        let parsedStorage = JSON.parse(loadedStorage);
        this.filter = this.installationName ? this.installationName : parsedStorage.filter;
        this.blockLoading = true;
      }
    },
    kgm_selectRow () {
      this.$refs.searchBar.blur();
      kendoGridMixin.methods.kgm_selectRow.call(this);
    },
    initializeFilter () {
      this.kgm_filter = {
          logic: "and",
          filters: [
            {
              field: "name",
              operator: "contains",
              value: ""
            },
            {
              field: "id",
              operator: "contains",
              value: ""
            },
            {
              field: "country",
              operator: "contains",
              value: ""
            },
            {
              field: "sponsor",
              operator: "contains",
              value: ""
            },
            {
              field: "monitoring",
              operator: "contains",
              value: ""
            },
            {
              field: "slaAgreement",
              operator: "contains",
              value: ""
            }
          ]
        };
    },
    searchChange () {
      if(!this.kgm_filter)
      {
        this.initializeFilter();
      }
      let filter = this.filter.trim();
      if(!isNaN(filter) || filter.startsWith('SKISUP-') && !isNaN(filter.substring(7))) //we filter by Id
      {
         this.kgm_filter.filters.splice(this.kgm_filter.filters.findIndex(item => item.name === "andOperator"), 1)
          let idFilter = this.kgm_filter.filters.find(f => f.field == 'id');
          if(idFilter)
          {
            idFilter.value = filter;
          }
          else
          {
            let newFilter = {
                field: "id",
                operator: "contains",
                value: filter
              };
            this.kgm_filter.filters.push(newFilter);
          }
      }
      else //we filter by Name
      {
        this.kgm_filter.filters.splice(this.kgm_filter.filters.findIndex(item => item.name === "andOperator"), 1)
        let filterParts = filter.split(' ');
        if(filterParts.length == 1) //single name search
        { 
          let nameFilter = this.kgm_filter.filters.find(f => f.field == 'name');
          if(nameFilter)
          {
            nameFilter.value = filter;
          }
          else
          {
            let newFilter = {
                field: "name",
                operator: "contains",
                value: filter
              };
            this.kgm_filter.filters.push(newFilter);
          }
        }
        else // search by multiple names
        {
          let andOperator =  {    
                name: "andOperator",         
                logic: "and",
                filters: []
              };
          filterParts.forEach(filter => {
            andOperator.filters.push(
              {
                field: "name",
                operator: "contains",
                value: filter
              }
            );
          });
          let existingAndOperator = this.kgm_filter.filters.find(p => p.name == "andOperator");
          if(existingAndOperator)
          {
            this.kgm_filter.filters.splice(this.kgm_filter.filters.findIndex(item => item.name === "andOperator"), 1)
          }
          this.kgm_filter.filters.push(andOperator);
        }
      }
      this.saveFilter();
    },
    computedStatus (status) {
      if (!status) {
        return this.jiraStatus_statusToObject("unknown");
      }
      if (status.length === 0) {
        return this.jiraStatus_statusToObject("unknown");
      }
      return this.jiraStatus_statusToObject(status);
    },
    // GET INSTALLATIONS gets the installations from the database
    getAllInstallations () {
      this.loading = true;
      this.axios.get(`/Installation/GetAll?showAll=${this.showAll}&allTriggers=true${this.installationTypeQuery ? '&filterTypes=' + this.installationTypeQuery : ''}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.installations = response.data;
          this.installations.forEach(element => {
            if(!element.slaAgreement) {
              element.slaAgreement = "No Agreement";
            }
          });
          this.initInstallations = response.data;
          this.filterSkimovieType();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    autoLoadInstallations () {
      this.loading = true;
      this.axios.post('/Installation/RefreshInstallationTriggers', this.installations)
        .then((response) => {
          this.installations = response.data;
          this.initInstallations = response.data;
          this.filterSkimovieType();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
<style>
.installationList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}

span.Deactivated {
  color: gray;
}

span.OK {
  color: darkgreen;
}

span.Information {
  color: #7499FF;
}

span.Warning {
  color: #FFC859;
}

span.Average {
  color: #FFA059;
}

span.High {
  color: #E97659;
}

span.Disaster {
  color: #E45959;
}
</style>
